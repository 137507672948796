<!--
 * @Author: Dyf
 * @Date: 2023-11-07 14:38:05
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-11-20 15:53:38
 * @Description: 意见反馈
-->
<style lang="scss" scoped>
.feedback {
  @include innerPage;
  .feeback-form {
    width: 100%;
    box-sizing: border-box;
    padding: 32px 50px;
    height: calc(100% - 64px);
    background-color: #ffffff;
    border-radius: 30px;
    margin-top: 32px;
    .el-form {
      max-width: 800px;
    }
    .img-uploader {
      min-width: 102px;
      min-height: 102px;

      ::v-deep .el-upload--text {
        min-height: 102px;
        height: auto;
      }

      .img-list {
        width: 366px;
        display: flex;
        flex-wrap: wrap;
      }
      .img-box {
        height: 102px;
        margin-right: 20px;
        margin-bottom: 20px;
        position: relative;

        .el-image {
          border-radius: 10px;
        }

        .del {
          width: 24px;
          height: 24px;
          background: #fff;
          position: absolute;
          top: -8px;
          right: -8px;
          font-size: 32px;
          border-radius: 50%;
          color: #f8516d;
          @include flexBox(center);
        }
      }

      .upload-wrapper {
        width: 102px;
        height: 102px;
        background: #2ac293;
        border-radius: 10px;
        color: #fff;
        font-size: 50px;
        @include flexBox(center);
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .feedback .feeback-form {
    height: calc(100% - 18px);
    margin-top: 18px;
  }
}
</style>

<template>
  <section class="feedback">
    <div class="feeback-form">
      <el-form
        ref="formData"
        :model="formData"
        label-width="68px"
        :rules="rules"
      >
        <el-form-item label="反馈内容" class="textarea" prop="plfee_content">
          <el-input
            v-model.trim="formData.plfee_content"
            type="textarea"
            maxlength="200"
            rows="10"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item label="上传图片" prop="plfee_image">
          <el-upload
            class="img-uploader"
            ref="imgUploader"
            :action="$upload.imgAction"
            :headers="$upload.header" 
            :show-file-list="formData.plfee_image.length==0" 
            :file-list="formData.plfee_image" 
            :accept="$upload.imgAccept"
            :before-upload="$beforImgUpload"
            :on-success="
              (res) => {
                return uploadImgSuccess(res);
              }
            "
            :on-exceed="
              () => {
                $message.warning('上传文件数量超出限制，最多上传9个');
              }
            "
            multiple
            :limit="9"
          >
            <div class="img-list">
              <template v-if="formData.plfee_image.length">
                <div
                  class="img-box"
                  v-for="(item, index) in formData.plfee_image"
                  :key="index"
                >
                  <el-image
                    style="width: 102px; height: 102px"
                    :src="formatfile(item)"
                    :preview-src-list="[formatfile(item)]"
                  />
                  <i class="del iconfont" @click.stop="delImg(index)"
                    >&#xe63e;</i
                  >
                </div>
              </template>
              <div
                class="upload-wrapper"
                v-if="formData.plfee_image.length < 9"
              >
                <i class="iconfont">&#xe63d;</i>
              </div>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button
            type="custom_primary"
            size="small"
            @click="handleSubmit"
            v-loading="btnload"
            >确 定</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </section>
</template>

<script>
import { plafeedbackAdd } from "@api/center";
import { formatFile } from "@utils";
export default {
  computed: {
    formatfile() {
      return function (url) {
        return formatFile(url);
      };
    },
  },
  data() {
    return {
      formData: {
        plfee_image: [],
      },
      btnload: false,
      rules: {
        plfee_content: [
          { required: true, message: "请填写反馈内容", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    /**
     * 图片上传成功
     * @param {object} data 响应数据
     * @param {string} el 图片对应字段变量
     */
    uploadImgSuccess({ data, el }) {
      if (this.$isEmpty(data)) return this.$message.error(msg);
      this.formData.plfee_image.push(data.address);
      this.$forceUpdate();
    },
    /** 删除图组图片 */
    delImg(index) {
      let imgs = [...this.formData.plfee_image];
      imgs.splice(index, 1);
      this.$refs.imgUploader.uploadFiles.splice(index, 1);
      this.formData = {
        ...this.formData,
        plfee_image: [...imgs],
      };
    },
    /** 反馈提交 */
    handleSubmit() {
      this.$refs.formData.validate(async (valid) => {
        if (valid) {
          this.btnload = true;
          let params = {
            plfee_content: this.formData.plfee_content,
            plfee_image: this.formData.plfee_image.join(","),
          };
          let res = await plafeedbackAdd(params);
          this.btnload = false;
          // 密码修改成功后跳转重新登录
          if (res) {
            this.formData = {
              plfee_image: [],
            };
            this.$message.success("提交成功");
            this.$refs.formData.resetFields();
          }
        }
      });
    },
  },
};
</script>